<template>
  <b-avatar variant="danger" v-if="isPdf">
    <i class="far fa-file-pdf"></i>
  </b-avatar>
  <b-avatar variant="info" v-else-if="isWord">
    <i class="far fa-file-word"></i>
  </b-avatar>
  <b-avatar variant="secondary" v-else-if="isImage">
    <i class="far fa-file-image"></i>
  </b-avatar>
  <b-avatar variant="secondary" v-else>
    <i class="far fa-file"></i>
  </b-avatar>
</template>
<script>
import { BAvatar } from "bootstrap-vue";

export default {
  components: { BAvatar },
  props: {
    mimeType: {
      type: String,
      default: ""
    }
  },
  computed: {
    isPdf() {
      return this.mimeType === "application/pdf";
    },
    isWord() {
      return (
        this.mimeType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        this.mimeType === "application/msword"
      );
    },
    isImage() {
      return this.mimeType === "image/png" || this.mimeType === "image/jpeg";
    }
  }
};
</script>
