<template>
  <div v-if="application.candidate">
    <b-modal
      :cancel-title="$gettext('Cancel')"
      :title="$gettext('Add a document')"
      @ok="handleAddDocument"
      id="add-document-modal"
      :ok-disabled="isPosting"
      :cancel-disabled="isPosting"
      hide-header-close
    >
      <form @submit.prevent="handleAddDocument">
        <FormGroupMultiselect
          :label="$gettext('Type')"
          field="type"
          :options="[
            { id: 'resume', label: 'Resume' },
            { id: 'cover_letter', label: 'Cover letter' }
          ]"
          option-label="label"
          track-by="id"
          v-model="documentPayload.type"
        />

        <FormGroupFile
          :label="$gettext('Document')"
          field="document"
          v-model="documentPayload.file"
        />
      </form>
    </b-modal>
    <b-modal
      @ok="handleOk"
      @show="resetModal"
      id="application-note-modal"
      :title="$gettext('Add note')"
      :cancel-title="$gettext('Cancel')"
      hide-header-close
      :ok-disabled="isPosting"
      :cancel-disabled="isPosting"
    >
      <b-form-textarea v-model="payload.note" />
    </b-modal>
    <div class="section-block">
      <div class="block-header">
        <div class="row">
          <div class="col-md-9 mb-3 mb-md-0">
            <h2 class="card-title">
              {{ application.candidate.full_name }}
            </h2>
          </div>
          <div class="col-md-3 text-right">
            <b-form-select
              v-if="application.status"
              v-model="application.status"
              :options="applicationStatusOptions"
              @input="handleStatusChange"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div class="block-content">
        <div class="row">
          <div class="col-md-4">
            <label><translate>First name</translate></label>
            <p>{{ application.candidate.first_name }}</p>
          </div>
          <div class="col-md-4">
            <label><translate>Last name</translate></label>
            <p>{{ application.candidate.last_name }}</p>
          </div>
          <div class="col-md-4">
            <label><translate>Email Address</translate></label>
            <p>{{ application.candidate.email }}</p>
          </div>
          <div class="col-md-4">
            <label><translate>Phone</translate></label>
            <p>{{ application.candidate.phone_number }}</p>
          </div>
          <div class="col-md-4">
            <label><translate>City</translate></label>
            <p>{{ candidateCity }}</p>
          </div>
          <div class="col-md-4">
            <label><translate>Application date</translate></label>
            <p><FormattedDate :date="application.created_at" /></p>
          </div>
          <div class="col-md-4">
            <label><translate>Source</translate></label>
            <p>{{ getJobboardSourceLabel(application.source) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-block">
      <div class="block-header">
        <div class="row">
          <div class="col-7">
            <h2 class="card-title"><translate>Documents</translate></h2>
          </div>
          <div class="col-5 text-right">
            <button
              v-if="canManageCandidates"
              class="btn btn-primary"
              v-b-modal.add-document-modal
              data-target="#add-document-dialog"
              data-toggle="modal"
              type="button"
            >
              <translate>Add Document</translate>
            </button>
          </div>
        </div>
      </div>
      <div class="block-content">
        <DocumentViewerModal
          :url="documentViewed.url"
          :title="documentViewed.title"
          :mime-type="documentViewed.mimeType"
        />
        <b-table
          :items="application.documents"
          :fields="fields"
          thead-class="d-none"
          table-class="line-between-rows"
        >
          <template #cell(icon)="{item}">
            <div class="img-wrapper">
              <MimeTypeAvatar :mime-type="item.mime_type" />
            </div>
          </template>
          <template #cell(pivot.type)="{item}">
            {{ translateDocumentType(item.pivot.type) }}
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="handleViewDocument(data.item)"
              class="btn btn-info btn-icon btn-sm"
              v-b-tooltip.hover.top="$gettext('View')"
              ><i class="fa fa-eye"></i
            ></b-button>
            <a
              class="btn btn-success btn-icon btn-sm ml-1"
              :href="data.item.cdn"
              target="_blank"
              v-b-tooltip.hover.top="$gettext('Download')"
              ><i class="fa fa-download"></i
            ></a>
            <b-button
              :disabled="!data.item.pivot.is_internal"
              @click="handleDeleteDocument(data.item.id)"
              class="btn btn-danger btn-icon btn-sm ml-1"
              v-b-tooltip.hover.top="$gettext('Delete')"
              ><i class="fa fa-times"></i
            ></b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="section-block">
      <div class="block-header">
        <div class="row">
          <div class="col-7">
            <h2 class="card-title"><translate>Application note</translate></h2>
          </div>
          <div class="col-5 text-right">
            <button
              v-if="canManageCandidates"
              class="btn btn-primary"
              v-b-modal.application-note-modal
              data-target="#add-document-dialog"
              data-toggle="modal"
              type="button"
            >
              <translate>Add Note</translate>
            </button>
          </div>
        </div>
      </div>
      <div class="block-content">
        <table class="table line-between-rows">
          <tbody>
            <tr v-for="(note, index) in application.notes" :key="index">
              <td class="td-avatar">
                <div class="img-wrapper">
                  <img class="img-raised" :src="avatar(note.user)" />
                </div>
              </td>
              <td>{{ note.note }}</td>
              <td class="d-none d-md-table-cell">
                <FormattedDate :date="note.created_at" />
              </td>
              <td v-if="canManageCandidates" class="text-right">
                <b-button
                  v-if="userId === note.user_id"
                  @click="showEditNoteModal(note)"
                  variant="warning"
                  class="btn btn-icon btn-sm ml-1"
                  v-b-tooltip.hover.top="$gettext('Edit')"
                >
                  <i class="fa fa-edit"></i>
                </b-button>
                <b-button
                  v-if="userId === note.user_id"
                  @click="handleDeleteNote(note.id)"
                  variant="danger"
                  class="btn btn-icon btn-sm ml-1"
                  v-b-tooltip.hover.top="$gettext('Delete')"
                >
                  <i class="fa fa-times"></i>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormGroupFile from "@/components/FormGroups/FormGroupFile";
import FormGroupMultiselect from "@/components/FormGroups/FormGroupMultiselect";
import DocumentViewerModal from "@/components/Document/ViewerModal";
import { msgBoxConfirm } from "@/utils/modal";
import FormattedDate from "@/components/FormattedDate";
import { VBTooltip } from "bootstrap-vue";
import { jobboards } from "../../../enums/jobboards";
import { applicationStatus } from "@/enums/applicationStatus";
import MimeTypeAvatar from "@/components/MimeTypeAvatar.vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: {
    MimeTypeAvatar,
    FormattedDate,
    FormGroupFile,
    FormGroupMultiselect,
    DocumentViewerModal
  },
  props: { applicationId: { type: [Number, String], required: false } },
  data() {
    return {
      isEditingNote: false,
      documentPayload: {
        type: {},
        document: null
      },
      payload: {
        note: ""
      },
      fields: [
        { key: "icon", tdClass: "td-avatar" },
        { key: "pivot.type" },
        { key: "actions", tdClass: "text-right" }
      ],
      documentViewed: {
        url: "",
        title: "",
        mimeType: ""
      }
    };
  },
  async created() {
    if (this.applicationId) {
      this.startLoader("offer-application");
      await this.fetchOne(this.applicationId).finally(() =>
        this.stopLoader("offer-application")
      );
    }
  },
  computed: {
    ...mapGetters("offerApplications", { application: "item" }),
    ...mapGetters("me", ["canManageCandidates", "userId"]),
    ...mapGetters("form", ["isPosting"]),
    candidateCity() {
      return this.application.candidate?.location?.address || "-";
    },
    applicationStatusOptions() {
      return applicationStatus;
    }
  },
  methods: {
    ...mapActions("offerApplications", [
      "updateApplicationStatus",
      "createApplicationNote",
      "updateApplicationNote",
      "deleteApplicationNote"
    ]),
    ...mapActions("applications", ["addDocument", "deleteDocument"]),
    ...mapActions("offerApplications", ["fetchOne"]),
    ...mapActions("form", ["submit"]),
    ...mapActions("offers", ["fetchStats"]),
    ...mapMutations("offers", ["setApplicationId"]),
    ...mapMutations("app", ["startLoader", "stopLoader"]),
    showEditNoteModal({ id, note }) {
      this.$bvModal.show("application-note-modal");
      this.isEditingNote = true;
      this.payload.noteId = id;
      this.payload.note = note;
    },
    handleOk(event) {
      event.preventDefault();
      this.submit(() => {
        if (this.isEditingNote) {
          return this.updateApplicationNote({
            applicationId: this.application.id,
            note: this.payload.note,
            noteId: this.payload.noteId
          });
        } else {
          return this.createApplicationNote({
            applicationId: this.application.id,
            note: this.payload.note
          });
        }
      }).then(() => this.$bvModal.hide("application-note-modal"));
    },
    resetModal() {
      this.payload.note = "";
      this.isEditingNote = false;
    },
    handleViewDocument(document) {
      this.documentViewed = {
        url: document.cdn,
        title: this.translateDocumentType(document.pivot.type),
        mimeType: document.mime_type
      };
      this.$bvModal.show("document-viewer-modal");
    },
    handleDeleteDocument(documentId) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.deleteDocument({
            applicationId: this.applicationId,
            documentId
          }).then(() => this.$router.go(0));
        }
      });
    },
    handleAddDocument(event) {
      event.preventDefault();
      const form = new FormData();
      form.append("type", this.documentPayload.type.id);
      form.append("file", this.documentPayload.file);
      this.submit(() => {
        return this.addDocument({
          id: this.applicationId,
          form
        }).then(() => {
          this.$bvModal.hide("add-document-modal");
          this.$router.go(0);
        });
      });
    },
    handleDeleteNote(noteId) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.deleteApplicationNote({
            applicationId: this.application.id,
            noteId
          });
        }
      });
    },
    avatar(user) {
      return user.avatar || this.buildAvatarUrl(user);
    },
    buildAvatarUrl(user) {
      const initials = user.first_name.charAt(0) + user.last_name.charAt(0);
      return `https://ui-avatars.com/api/?length=2&name=${initials}&background=0D8ABC&color=fff`;
    },
    handleStatusChange(status) {
      this.updateApplicationStatus({
        id: this.application.id,
        status
      }).finally(() => {
        this.fetchStats(this.$route.params.offerId);
        this.$emit("statusChanged");
      });
    },
    translateDocumentType(type) {
      switch (type) {
        case "resume":
          return this.$gettext("Resume");
        case "cover_letter":
          return this.$gettext("Cover Letter");
      }
    },
    getJobboardSourceLabel(source) {
      return jobboards.from(source).label ?? source;
    }
  },
  watch: {
    "$route.params": {
      handler(current, previous) {
        if (
          current.applicationId &&
          parseInt(current.applicationId) !== parseInt(previous.applicationId)
        ) {
          this.startLoader("offer-application");
          this.fetchOne(current.applicationId).finally(() =>
            this.stopLoader("offer-application")
          );
        }
      }
    }
  }
};
</script>
